// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
const ROOTS_ACCOUNT = '/account';
const ROOTS_TRANSACTION = '/transactions';
const ROOTS_XRPL_WALLET = '/wallets';
const ROOTS_CONFIGURATION = '/configuration';
const ROOTS_MERCHANTS = '/merchants';
const ROOTS_SUPPORT = '/support';
const ROOTS_AUDIT_TRAIL = '/audit-trail/list';
const ROOTS_NOTIFICATION = '/notification';
const ROOTS_REPORTING = '/reporting';
const ROOTS_APP = '/app';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    new: path(ROOTS_DASHBOARD, '/account/new'),
    users: path(ROOTS_DASHBOARD, '/account/users'),
    cards: path(ROOTS_DASHBOARD, '/account/cards'),
    profile: path(ROOTS_DASHBOARD, '/account/profile'),
    account: path(ROOTS_DASHBOARD, '/account/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/account/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/account/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_ACCOUNT = {
  root: ROOTS_ACCOUNT,
  retailUsers: {
    root: path(ROOTS_ACCOUNT, '/retail-users'),
    profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  merchantUsers: {
    root: path(ROOTS_ACCOUNT, '/merchant-users'),
    profile: (id, tabname) => path(ROOTS_ACCOUNT, `/merchant-users/profile/${id}/${tabname}`),
    new: path(ROOTS_ACCOUNT, '/merchant-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  passwordChangeTable: path(ROOTS_ACCOUNT, '/password-change-table'),
  retailKycTable: {
    root: path(ROOTS_ACCOUNT, '/retail-kyc'),
    edit: (id) => path(ROOTS_ACCOUNT, `/retail-kyc/edit/${id}`),
    new: path(ROOTS_ACCOUNT, '/retail-kyc/new'),
  },
  merchantKybTable: {
    root: path(ROOTS_ACCOUNT, '/merchant-kyb'),
    edit: (id) => path(ROOTS_ACCOUNT, `/merchant-kyb/edit/${id}`),
    new: path(ROOTS_ACCOUNT, '/merchant-kyb/new'),
  },
};

export const PATH_APP = {
  root: ROOTS_CONFIGURATION,
  version: {
    root: path(ROOTS_CONFIGURATION, '/version'),
    new: path(ROOTS_CONFIGURATION, '/version/new'),
    edit: (id) => path(ROOTS_CONFIGURATION, `/version/${id}/edit`),
  },
};

export const PATH_AUDIT_TRAIL = {
  root: ROOTS_AUDIT_TRAIL,
};

export const PATH_NOTIFICATION = {
  root: ROOTS_NOTIFICATION,
  version: {
    root: path(ROOTS_NOTIFICATION, '/list'),
    new: path(ROOTS_NOTIFICATION, '/new'),
    edit: (id) => path(ROOTS_NOTIFICATION, `/${id}/edit`),
  },
};

export const PATH_REPORTING = {
  root: ROOTS_REPORTING,
  revenueReport: {
    root: path(ROOTS_REPORTING, '/revenue-report'),
    // profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    // new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  revenueComponentBreakReport: {
    root: path(ROOTS_REPORTING, '/revenue-component-break-report'),
    // profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    // new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  p2pTransactionVolume: {
    root: path(ROOTS_REPORTING, '/p2p-transaction-volume'),
    // profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    // new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  p2bTransactionVolume: {
    root: path(ROOTS_REPORTING, '/p2b-transaction-volume'),
    // profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    // new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  depositTransactionVolume: {
    root: path(ROOTS_REPORTING, '/deposit-transaction-volume'),
    // profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    // new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  withdrawVolume: {
    root: path(ROOTS_REPORTING, '/withdraw-volume'),
    // profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    // new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  billPaymentsVolume: {
    root: path(ROOTS_REPORTING, '/bill-payments-volume'),
    // profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    // new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
  balanceDiffrenceReport: {
    root: path(ROOTS_REPORTING, '/balance-diffrence-report'),
    // profile: (id, tabname) => path(ROOTS_ACCOUNT, `/retail-users/profile/${id}/${tabname}`),
    // new: path(ROOTS_ACCOUNT, '/retail-users/new'),
    // edit: (id) => path(ROOTS_ACCOUNT, `/retail-users/${id}/edit`),
  },
};

export const PATH_TRANSACTION = {
  root: ROOTS_TRANSACTION,
  bitgo: {
    root: path(ROOTS_TRANSACTION, '/bitgo'),
    bitgoTransaction: path(ROOTS_TRANSACTION, '/bitgo/transaction'),
  },
  wallet: {
    root: path(ROOTS_TRANSACTION, '/wallet'),
    walletTransaction: path(ROOTS_TRANSACTION, '/wallet/transaction'),
    edit: (id) => path(ROOTS_TRANSACTION, `/wallet/transaction/${id}/edit`),
  },
  fundingQueue: {
    root: path(ROOTS_TRANSACTION, '/funding-queue'),
    new: path(ROOTS_TRANSACTION, `/funding-queue/new`),
    edit: (id) => path(ROOTS_TRANSACTION, `/funding-queue/${id}/edit`),
  },
  eftAccounts: {
    root: path(ROOTS_TRANSACTION, '/eft-accounts'),
    // new: path(ROOTS_TRANSACTION, `/funding-queue/new`),
    // edit: (id) => path(ROOTS_TRANSACTION, `/funding-queue/${id}/edit`),
  },
};

export const PATH_XRPL_WALLET = {
  root: ROOTS_XRPL_WALLET,
  wallets: {
    root: path(ROOTS_XRPL_WALLET, '/wallets'),
    wallet: path(ROOTS_XRPL_WALLET, '/wallets/wallet'),
    edit: (id) => path(ROOTS_XRPL_WALLET, `/wallets/wallet/${id}/edit`),
  },
  centralWallets: {
    root: path(ROOTS_XRPL_WALLET, '/central-wallets'),
    wallet: path(ROOTS_XRPL_WALLET, '/central-wallets/wallet'),
    edit: (id) => path(ROOTS_XRPL_WALLET, `/central-wallets/wallet/${id}/edit`),
  },
  walletAccounts: {
    root: path(ROOTS_XRPL_WALLET, '/wallet-accounts'),
    wallet: path(ROOTS_XRPL_WALLET, '/wallet-accounts/wallet'),
  },
};
export const PATH_CONFIGURATION = {
  root: ROOTS_CONFIGURATION,
  adminEmails: {
    root: path(ROOTS_CONFIGURATION, '/admin-emails'),
    new: path(ROOTS_CONFIGURATION, `/admin-emails/new`),
    edit: (id) => path(ROOTS_CONFIGURATION, `/admin-emails/${id}/edit`),
  },
  accuantError: {
    root: path(ROOTS_CONFIGURATION, '/accuant-error'),
    new: path(ROOTS_CONFIGURATION, `/accuant-error/new`),
    edit: (id) => path(ROOTS_CONFIGURATION, `/accuant-error/${id}/edit`),
  },
  commissions: {
    root: path(ROOTS_CONFIGURATION, '/commissions'),
  },
  countrySupportedCoin: {
    root: path(ROOTS_CONFIGURATION, '/country-supported-coin'),
    new: path(ROOTS_CONFIGURATION, `/country-supported-coin/new`),
    edit: (id) => path(ROOTS_CONFIGURATION, `/country-supported-coin/${id}/edit`),
  },
  derogatoryWords: {
    root: path(ROOTS_CONFIGURATION, '/derogatory-words'),
    new: path(ROOTS_CONFIGURATION, `/derogatory-words/new`),
    edit: (id) => path(ROOTS_CONFIGURATION, `/derogatory-words/${id}/edit`),
  },
  coins: {
    root: path(ROOTS_CONFIGURATION, '/coins'),
    new: path(ROOTS_CONFIGURATION, `/coins/new`),
    edit: (id) => path(ROOTS_CONFIGURATION, `/coins/${id}/edit`),
  },
  appConfiguration: {
    root: path(ROOTS_CONFIGURATION, '/app-configuration'),
    new: path(ROOTS_CONFIGURATION, `/app-configuration/new`),
    edit: (id) => path(ROOTS_CONFIGURATION, `/app-configuration/${id}/edit`),
  },
  version: {
    root: path(ROOTS_CONFIGURATION, '/version'),
    new: path(ROOTS_CONFIGURATION, '/version/new'),
    edit: (id) => path(ROOTS_CONFIGURATION, `/version/${id}/edit`),
  },
  xrplStatusCodes: {
    root: path(ROOTS_CONFIGURATION, '/xrpl-status-codes'),
    new: path(ROOTS_CONFIGURATION, `/xrpl-status-codes/new`),
    edit: (id) => path(ROOTS_CONFIGURATION, `/xrpl-status-codes/${id}/edit`),
  },
  demographics: {
    root: path(ROOTS_CONFIGURATION, '/demographics'),
    currencies: {
      root: path(ROOTS_CONFIGURATION, '/demographics/currencies'),
      new: path(ROOTS_CONFIGURATION, '/demographics/currencies/new'),
      edit: (id) => path(ROOTS_CONFIGURATION, `/demographics/currencies/${id}/edit`),
    },
    countries: {
      root: path(ROOTS_CONFIGURATION, '/demographics/countries'),
      new: path(ROOTS_CONFIGURATION, '/demographics/countries/new'),
      edit: (id) => path(ROOTS_CONFIGURATION, `/demographics/countries/${id}/edit`),
    },
    provinces: {
      root: path(ROOTS_CONFIGURATION, '/demographics/provinces'),
      new: path(ROOTS_CONFIGURATION, '/demographics/provinces/new'),
      edit: (id) => path(ROOTS_CONFIGURATION, `/demographics/provinces/${id}/edit`),
    },
    languages: {
      root: path(ROOTS_CONFIGURATION, '/demographics/languages'),
      new: path(ROOTS_CONFIGURATION, '/demographics/languages/new'),
      edit: (id) => path(ROOTS_CONFIGURATION, `/demographics/languages/${id}/edit`),
    },
    exchangeRates: {
      root: path(ROOTS_CONFIGURATION, '/demographics/exchange-rates'),
      new: path(ROOTS_CONFIGURATION, '/demographics/exchange-rates/new'),
      edit: (id) => path(ROOTS_CONFIGURATION, `/demographics/exchange-rates/${id}/edit`),
    },
  },
};

export const PATH_MERCHANTS = {
  root: ROOTS_MERCHANTS,
  businessCategories: {
    root: path(ROOTS_MERCHANTS, '/business-categories'),
    new: path(ROOTS_MERCHANTS, '/business-categories/new'),
    edit: (id) => path(ROOTS_MERCHANTS, `/business-categories/${id}/edit`),
  },
  businessType: {
    root: path(ROOTS_MERCHANTS, '/business-type'),
    new: path(ROOTS_MERCHANTS, '/business-type/new'),
    edit: (id) => path(ROOTS_MERCHANTS, `/business-type/${id}/edit`),
  },
  merchantConfig: {
    root: path(ROOTS_MERCHANTS, '/merchant-config'),
    new: path(ROOTS_MERCHANTS, '/merchant-config/new'),
    edit: (id) => path(ROOTS_MERCHANTS, `/merchant-config/${id}/edit`),
  },
  merchantRoles: {
    root: path(ROOTS_MERCHANTS, '/merchant-roles'),
    new: path(ROOTS_MERCHANTS, '/merchant-roles/new'),
    edit: (id) => path(ROOTS_MERCHANTS, `/merchant-roles/${id}/edit`),
  },
  merchants: {
    root: path(ROOTS_MERCHANTS, '/merchants'),
    new: path(ROOTS_MERCHANTS, '/merchants/new'),
    edit: (id) => path(ROOTS_MERCHANTS, `/merchants/${id}/edit`),
  },
  merchantStores: {
    root: path(ROOTS_MERCHANTS, '/merchant-stores'),
    new: path(ROOTS_MERCHANTS, '/merchant-stores/new'),
    edit: (id) => path(ROOTS_MERCHANTS, `/merchant-stores/${id}/edit`),
  },
  merchantDocumentTypes: {
    root: path(ROOTS_MERCHANTS, '/merchant-document-types'),
    new: path(ROOTS_MERCHANTS, '/merchant-document-types/new'),
    edit: (id) => path(ROOTS_MERCHANTS, `/merchant-document-types/${id}/edit`),
  },
};

export const PATH_SUPPORT = {
  root: ROOTS_SUPPORT,
  support: {
    root: path(ROOTS_SUPPORT, '/support'),
    support: (type) => path(ROOTS_SUPPORT, `/${type}`),
    view: (type, name) => path(ROOTS_DASHBOARD, `/support/${type}/${name}`),
    merchantSupport: {
      root: path(ROOTS_SUPPORT, '/merchant'),
      supportCategories: {
        root: path(ROOTS_SUPPORT, '/merchant/support-categories'),
        new: path(ROOTS_SUPPORT, '/merchant/support-categories/new'),
        edit: (id) => path(ROOTS_SUPPORT, `/merchant/support-categories/${id}/edit`),
      },
      supportTicket: {
        root: path(ROOTS_SUPPORT, '/merchant/support-ticket/merchant'),
        view: (id) => path(ROOTS_SUPPORT, `/merchant/support-ticket/merchant/${id}`),
      },
      merchantFAQ: {
        root: path(ROOTS_SUPPORT, '/merchant/merchant-faq'),
        new: path(ROOTS_SUPPORT, '/merchant/merchant-faq/new'),
        edit: (id) => path(ROOTS_SUPPORT, `/merchant/merchant-faq/${id}/edit`),
      },
      priority: {
        root: path(ROOTS_SUPPORT, '/merchant/priority'),
        new: path(ROOTS_SUPPORT, '/merchant/priority/new'),
        edit: (id) => path(ROOTS_SUPPORT, `/merchant/priority/${id}/edit`),
      },
    },
    userSupport: {
      root: path(ROOTS_SUPPORT, '/user'),
      supportTicket: {
        root: path(ROOTS_SUPPORT, '/user/support-ticket/user'),
        view: (id) => path(ROOTS_SUPPORT, `/user/support-ticket/user/${id}`),
      },
    },
    agentSupport: {
      root: path(ROOTS_SUPPORT, '/agents'),
      new: path(ROOTS_SUPPORT, '/agents/new'),
      edit: (id) => path(ROOTS_SUPPORT, `/agents/${id}/edit`),
    },
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
